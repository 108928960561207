<template>
  <div>
    <v-tabs
      align-with-title
    >
      <v-tabs-slider color="accent"></v-tabs-slider>

      <v-tab
        :to="{ name: 'Browse Import Profiles' }"
      >
      Browse Import Profiles
      </v-tab>
      <v-tab
        :to="{ name: 'Transform Mapper (beta)' }"
      >
      Transform Mapper (beta)
      </v-tab>
    </v-tabs>
    <router-view />
  </div>
</template>

<script>

export default {
  name: 'ProfileConfig',
  components: {
  },
};
</script>
